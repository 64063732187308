<template>
  <div class="home">
    <div class="datetime"></div>
    <div class="title"><img src="@/assets/title.png" /></div>
    <div class="content">
      <div class="left">
        <data-panel title="基本数据" :border="true">
          <div class="base">
            <div class="item">
              种植面积：<span>{{ data.base.plant_area }}</span> 亩
            </div>
            <div class="item">
              农户总数：<span> {{ data.base.farmer_count }}</span> 户
            </div>
            <div class="item">
              人口总数：<span>{{ data.base.population }}</span>
            </div>
            <div class="item">
              年总产值：<span>{{ data.base.amount_wan_yearly }}</span> 万
            </div>
          </div>
        </data-panel>
        <data-panel title="进口数量预估" border>
          <div id="pie" class="pie"></div>
        </data-panel>
        <data-panel title="销量预估">
          <div class="pre-sales">
            <div
              v-for="(item, index) in data.pre_sales"
              :key="`${index}`"
              class="item"
            >
              <div class="title">
                <span class="number">1</span> {{ item.name }}
              </div>
              <div class="content">
                <div class="bar-container">
                  <div
                    class="bar"
                    :style="{ width: (item.value / max_pre_sales) * 100 + '%' }"
                  ></div>
                </div>
                <div class="data">{{ item.value }}</div>
              </div>
            </div>
          </div>
        </data-panel>
      </div>
      <div class="middle">
        <data-panel title="基地监控实况">
          <div id="map" class="map"></div>
          <div class="info">
            <div class="line">
              <div class="item">
                <div class="title">交易量</div>
                <span
                  v-for="(letter, index) in String(data.trading_amount)"
                  :key="`${index}`"
                  class="letter"
                >
                  {{ letter }}
                </span>
              </div>
            </div>
            <div class="line">
              <div class="item">
                <div class="title">种植基地</div>
                <span
                  v-for="(letter, index) in String(data.base_count)"
                  :key="`${index}`"
                  class="letter"
                >
                  {{ letter }}
                </span>
                个
              </div>
              <div class="item">
                <div class="title">已接入</div>
                <span
                  v-for="(letter, index) in String(data.joined_percent)"
                  :key="`${index}`"
                  class="letter"
                >
                  {{ letter }}
                </span>
                %
              </div>
            </div>
          </div>
        </data-panel>
      </div>
      <div class="right">
        <div class="sale">
          <data-panel title="价格实况">
            <div class="price-realtime">
              <div v-for="item in data.price" :key="item.name" class="item">
                <div class="content">
                  <div class="price">
                    <span class="symbol">￥</span>{{ item.value.toFixed(2) }}
                  </div>
                  <div class="name">{{ item.name }}</div>
                </div>
                <div v-if="item.delta_percent >= 0.01" class="delta up">
                  +{{ item.delta_percent.toFixed(2) }}% ⬆
                </div>
                <div
                  v-else-if="Math.abs(item.delta_percent) < 0.01"
                  class="delta"
                >
                  =
                </div>
                <div v-else class="delta down">
                  {{ item.delta_percent.toFixed(2) }}% ⬇
                </div>
              </div>
            </div>
          </data-panel>
          <data-panel title="销售集散市场">
            <div class="market">
              <div class="item header">
                <div class="left">市场名称</div>
                <div class="right">零售额（万）</div>
              </div>
              <div v-for="item in data.market" :key="item.name" class="item">
                <div class="left">{{ item.name }}</div>
                <div class="right">{{ item.value.toFixed(1) }}</div>
              </div>
            </div>
          </data-panel>
          <data-panel title="销量实时情况" border>
            <div class="sales-realtime">
              <div id="line" class="line"></div>
            </div>
          </data-panel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import * as echarts from "echarts";
import "echarts-countries-js/echarts-countries-js/world";
import DataPanel from "@/components/DataPanel";
import asia_se from "@/lib/asiase";
import dayjs from "dayjs";
export default {
  name: "HomeView",
  components: { DataPanel },
  setup() {
    onMounted(() => {
      echarts.registerMap("asia_se", asia_se);
      const map = echarts.init(document.getElementById("map"));
      const positions = {
        南宁: [108.371442, 22.814612],
        缅甸: [96.075923, 19.762762],
        老挝: [102.633001, 17.976338],
        泰国: [100.477508, 13.745907],
        越南: [105.831336, 21.026761],
        柬埔寨: [104.899928, 11.569252],
        菲律宾: [120.984817, 14.607127],
        新加坡: [103.855193, 1.304053],
        文莱: [114.938605, 4.892826],
        马来西亚: [101.687717, 3.139581],
        印度尼西亚: [106.836541, -6.21289],
      };
      const getLine = (from, to) => {
        return {
          fromName: from,
          toName: to,
          coords: [positions[from], positions[to]],
        };
      };
      map.setOption({
        title: [
          {
            textStyle: {
              color: "#000",
              fontSize: 18,
            },
            textAlign: "center",
            left: "center",
            subtextStyle: {
              color: "#aaa",
              fontSize: 12,
            },
          },
        ],
        legend: [
          {
            selectedMode: "multiple",
            top: "top",
            orient: "horizontal",
            data: [""],
            left: "center",
            show: true,
          },
        ],
        geo: {
          map: "asia_se",
          roam: false,
          itemStyle: {
            areaColor: "rgba(8,38,81,0.8)", //设置区域颜色
            borderColor: "rgb(48,80,116)", //设置各各省市边界颜色
          },
          emphasis: {
            show: true,
            itemStyle: {
              areaColor: "#5588aa",
            },
            label: {
              show: false,
            },
          },
          label: {
            show: false,
          },
        },
        series: [
          {
            type: "lines",
            zlevel: 2,
            symbol: ["none", "arrow"], //线两端的标记类型，可以是一个数组分别指定两端
            symbolSize: 10,
            // effect: {
            //   show: true,
            //   period: 6,
            //   trailLength: 0,
            //   symbol: "arrow",
            //   symbolSize: 15,
            // },
            lineStyle: {
              color: "#46bee9",
              width: 1,
              opacity: 0.6,
              curveness: 0.2,
            },
            data: Object.keys(positions)
              .slice(1)
              .map((item) => getLine(item, "南宁")),
          },
          {
            type: "effectScatter", //带有涟漪特效动画的散点（气泡）图。利用动画特效可以将某些想要突出的数据进行视觉突出。
            coordinateSystem: "geo", //该系列使用的坐标系
            zlevel: 2,
            rippleEffect: {
              //涟漪特效相关配置
              brushType: "stroke",
            },
            label: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
            symbolSize: 10,
            itemStyle: {
              color: "#46bee9",
            },
            data: Object.keys(positions)
              .slice(1)
              .map((item) => ({
                name: item,
                value: positions[item],
              })),
          },
          {
            type: "effectScatter", //带有涟漪特效动画的散点（气泡）图。利用动画特效可以将某些想要突出的数据进行视觉突出。
            coordinateSystem: "geo", //该系列使用的坐标系
            zlevel: 2,
            rippleEffect: {
              //涟漪特效相关配置
              brushType: "stroke",
            },
            label: {
              show: true,
              position: "right",
              formatter: "{b}",
            },
            symbolSize: 20,
            itemStyle: {
              color: "#46bee9",
            },
            data: [
              {
                name: "南宁",
                value: positions["南宁"],
              },
            ],
          },
        ],
      });

      const pie = echarts.init(document.getElementById("pie"));
      pie.setOption({
        tooltip: {
          trigger: "item",
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          top: "5%",
          left: "center",
          textStyle: {
            color: "#fff",
          },
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "70%"],
            startAngle: 0,
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            data: [
              { value: 456554, name: "猫山王" },
              { value: 254324, name: "苏丹王" },
              { value: 202342, name: "金枕" },
              { value: 50455, name: "其它" },
            ],
          },
        ],
      });

      let lineData = [];
      for (let i = 0; i < data.sales.length; i++) {
        lineData.push({
          data: [
            {
              name: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              value: [
                dayjs().format("YYYY-MM-DD HH:mm:ss"),
                data.sales[i].value,
              ],
            },
          ],
        });
      }
      const line = echarts.init(document.getElementById("line"));
      line.setOption({
        legend: {
          data: data.sales.map((item) => item.name),
          textStyle: {
            color: "white",
          },
        },
        xAxis: {
          type: "time",
          minInterval: 10000,
          splitLine: {
            show: false,
          },
          axisLabel: { color: "white" },
        },
        yAxis: {
          type: "value",
          axisLabel: { color: "white" },
        },
        tooltip: {
          order: "valueDesc",
          trigger: "axis",
        },
        grid: {
          show: false,
          left: "3%",
          right: "4%",
          bottom: "5%",
          containLabel: true,
        },
        series: data.sales.map((item) => ({
          type: "line",
          smooth: true,
          name: item.name,
        })),
      });
      setInterval(function () {
        for (let i = 0; i < data.sales.length; i++) {
          data.sales[i].value += Math.random() * 1000;
          if (lineData[i].data.length > 20) {
            lineData[i].data.shift();
          }
          lineData[i].data.push({
            name: data.sales[i].name,
            value: [dayjs().format("YYYY-MM-DD HH:mm:ss"), data.sales[i].value],
          });
        }
        line.setOption({
          series: lineData,
        });
      }, 1000);
    });
    const data = reactive({
      lastYearProductionCapacity: 21045,
      currentProductionCapacity: 24429,
      totalProductionAmount: 3455060606,
      currentTotalSales: 1312412567,
      base: {
        plant_area: 268089,
        farmer_count: 12589,
        population: 15624,
        amount_wan_yearly: 2800,
      },
      price: [
        { name: "猫山王", value: 180.58, delta_percent: 0 },
        { name: "苏丹王", value: 125.1, delta_percent: 0 },
        { name: "金枕", value: 101.89, delta_percent: 0 },
      ],
      market: [
        {
          name: "海吉星市场",
          value: 782.6,
        },
        {
          name: "海湾农资市场",
          value: 623.8,
        },
        {
          name: "石塘市场",
          value: 622.4,
        },
        {
          name: "七冬坡农贸市场",
          value: 582.6,
        },
        {
          name: "大嘉汇市场",
          value: 526.7,
        },
      ],
      pre_sales: [
        {
          name: "猫山王",
          value: "5737",
        },
        {
          name: "苏丹王",
          value: "4568",
        },
        {
          name: "金枕",
          value: "5731",
        },
        {
          name: "金凤",
          value: "4568",
        },
        {
          name: "竹脚",
          value: "3558",
        },
      ],
      sales: [
        {
          name: "猫山王",
          value: 6547,
        },
        {
          name: "苏丹王",
          value: 3247,
        },
        {
          name: "金枕",
          value: 2557,
        },
      ],
      trading_amount: 3457168,
      base_count: 2683,
      joined_percent: 82,
    });
    setInterval(() => {
      data.trading_amount += Math.floor(Math.random() * 1000);

      for (const item of data.market) {
        item.value += Math.floor(Math.random() * 50) / 10;
      }

      for (const item of data.price) {
        const random = (Math.random() - 0.5) * 5;
        item.delta_percent = random / item.value;
        item.value += random;
      }
    }, 1000);
    const max_pre_sales = ref(
      Math.max(...data.pre_sales.map((item) => item.value))
    );
    watch(
      () => data.pre_sales,
      () => {
        max_pre_sales.value = Math.max(
          ...data.pre_sales.map((item) => item.value)
        );
      }
    );
    return { data, max_pre_sales };
  },
};
</script>
<style lang="scss" scoped>
.home {
  color: white;
  background: rgba(0, 0, 0, 0.4);
  * {
    transition: 2s;
  }
  > .title {
    text-align: center;
    font-size: 2rem;
    img {
      max-width: 100%;
    }
  }
  .content {
    display: flex;
    .left {
      width: 30%;
      padding-left: 20px;
      .base {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        .item {
          width: 50%;
          margin-bottom: 10px;
          span {
            color: rgb(82, 255, 255);
          }
        }
      }
      .pre-sales {
        .item {
          margin-bottom: 10px;
          .title {
            margin-bottom: 5px;
            .number {
              color: rgb(82, 255, 255);
              margin-right: 10px;
            }
          }
          .content {
            display: flex;
            .bar-container {
              flex: 1;
              background: rgba(255, 255, 255, 0.2);
              background-clip: content-box;
              margin-right: 20px;
              border-radius: 4px;
              .bar {
                border-radius: 4px;
                height: 100%;
              }
            }
            .data {
              color: rgb(82, 255, 255);
            }
          }
        }
        .item:nth-child(n + 1) .bar {
          background-image: linear-gradient(90deg, #65b4ff 0%, #9ffcea 100%);
        }
        .item:nth-child(n + 2) .bar {
          background-image: linear-gradient(90deg, #ff9744 0%, #ffd164 100%);
        }
        .item:nth-child(n + 3) .bar {
          background-image: linear-gradient(90deg, #8d23ff 0%, #ef39fe 100%);
        }
        .item:nth-child(n + 4) .bar {
          background-image: linear-gradient(90deg, #43ea80 0%, #38f8d4 100%);
        }
        .item:nth-child(n + 5) .bar {
          background-image: linear-gradient(90deg, #f5eded 0%, #feb0ab 100%);
        }
      }
      .pie {
        margin-top: 30px;
        width: 100%;
        height: 400px;
      }
    }
    .middle {
      position: relative;
      width: 40%;
      .info {
        position: absolute;
        top: 0;
        left: 0;
        .line {
          display: flex;
          margin-bottom: 20px;
        }
        .item {
          .title {
            font-size: 1.4rem;
          }
          .letter {
            font-size: 3rem;
            margin-right: 4px;
            color: white;
            background: rgb(3, 120, 197);
            border-radius: 5px;
            padding: 0 5px;
            box-sizing: border-box;
          }
        }
        .item:not(:first-child) {
          margin-left: 20px;
        }
      }
      .map {
        width: 100%;
        height: 800px;
      }
    }
    .right {
      width: 30%;
      padding-right: 20px;

      .price-realtime {
        .item {
          display: flex;
          margin-bottom: 10px;
          background: rgba(255, 255, 255, 0.2);
          .content {
            flex: 2;
            display: flex;
            align-items: flex-end;
            padding: 20px;
            .price {
              flex: 1;
              font-size: 2rem;
              .symbol {
                font-size: 1rem;
              }
            }
            .name {
              flex: 1;
            }
          }

          .delta {
            flex: 1;
            font-size: 2rem;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            background: rgb(106, 120, 215);
            align-self: stretch;
          }
          .delta.up {
            background: red;
          }
          .delta.down {
            background: green;
          }
        }
      }
      .market {
        .item {
          display: flex;
          margin-bottom: 5px;
          padding: 10px 0;
          justify-content: space-between;
          border: 2px rgb(60, 98, 192) solid;
        }
        .item.header {
          color: rgb(82, 255, 255);
        }
        .item:nth-child(odd) {
          background: rgba(255, 255, 255, 0.2);
        }
      }
      .sales-realtime {
        .line {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}
</style>
