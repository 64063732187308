<template>
  <div class="container">
    <div class="title">
      <span> {{ title }}</span>
    </div>
    <div class="content" :class="{ border: border }">
      <img v-if="border" class="left-bottom" src="@/assets/left-bottom.png" />
      <img v-if="border" class="right-top" src="@/assets/right-top.png" />
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "DataPanel",
  props: {
    title: String,
    border: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.container {
  padding: 10px;

  .title {
    font-size: 1.2rem;
    border-left: 4px rgb(82, 255, 255) solid;
    background: rgba(255, 255, 255, 0.3);
    color: rgb(82, 255, 255);
    span {
      display: block;
      margin-left: 20px;
      padding: 10px 0;
    }
  }
  .content.border {
    border: 2px rgb(66, 104, 201) solid;
    padding: 20px;
  }
  .content {
    position: relative;
    margin-top: 20px;

    box-sizing: border-box;
    .left-bottom {
      position: absolute;
      left: -2px;
      bottom: -2px;
    }
    .right-top {
      position: absolute;
      right: -2px;
      top: -2px;
    }
  }
}
</style>
